import React, { useEffect, useRef } from "react";

import resume from "../images/resume.pdf";
import { jobs } from "../library/job";

export type resumeDetails =
  | "none"
  | "epic"
  | "semaphore"
  | "darkTerritory"
  | "change"
  | "zynga";

interface ResumeProps {
  selectedResumeDetails: resumeDetails;
}

const skills = [
  "React/Redux",
  "Angular",
  "Electron",
  "Typescript",
  "Node.js",
  "CSS/SASS",
  "PHP",
  "Docker",
  "Kubernetes",
  "Argo CD",
  "Unity C#",
  "ShaderLab",
  "Ethereum Solidity",
  "Lodash",
  "GatsbyJS",
  "jQuery",
  "Mochajs",
  "Rendr",
  "Postgres",
  "MySQL",
  "Ruby on Rails",
  "Objective-C",
  "Java",
  "Jira",
  "Git",
  "Python",
  "Jenkins",
  "SQL Server",
];

export default function Resume({ selectedResumeDetails }: ResumeProps) {
  const epicRef = useRef<HTMLDivElement>(null);
  const semaphoreRef = useRef<HTMLDivElement>(null);
  const darkTerritoryRef = useRef<HTMLDivElement>(null);
  const changeRef = useRef<HTMLDivElement>(null);
  const zyngaRef = useRef<HTMLDivElement>(null);

  const getRef = (company: string) => {
    switch (company) {
      case "epic":
        return epicRef;
      case "semaphore":
        return semaphoreRef;
      case "darkTerritory":
        return darkTerritoryRef;
      case "change":
        return changeRef;
      case "zynga":
        return zyngaRef;
    }

    return null;
  };

  useEffect(() => {
    const ref = getRef(selectedResumeDetails);
    ref?.current?.scrollIntoView();
  }, [selectedResumeDetails]);

  return (
    <div>
      <div className="google-docs-link hidden-sm-down">
        <a href={resume} target="_blank">
          PDF Version
        </a>
      </div>
      <div className="resume-name">Adam Flesher</div>
      <div>
        <i>Address and phone # available upon request</i>
        <br />
        <a href="mailto:adam.flesher@gmail.com">adam.flesher@gmail.com</a>
      </div>
      <div className="resume-heading">Technical Proficiencies</div>
      <div>{skills.join(", ")}</div>
      <div className="resume-heading">Experience</div>
      {jobs.map(({ company, position, date, children, location, key }) => (
        <div ref={getRef(key)} key={key}>
          {" "}
          <div className="resume-company">
            <b>
              {company}, {location}
            </b>
            <i> - {position}</i>
          </div>
          <div className="resume-dates">{date}</div>
          {children}
        </div>
      ))}
      <div className="resume-heading">Education</div>
      <div className="resume-company">
        <b>Camosun College, Victoria BC</b>
        <i> - Diploma in Computer Science</i>
      </div>
      <div className="resume-dates">SEP 2002 - APR 2004</div>
    </div>
  );
}
