import React from "react";
import { IExperience } from "../types/experience";

interface ExperienceProps extends IExperience {
  onDetailsClick: () => void;
}

export default function Experience({
  icon,
  name,
  title,
  date,
  onDetailsClick,
  children,
}: ExperienceProps) {
  return (
    <div>
      <div className="company">
        <div className="icon">
          <img src={icon} />
        </div>
        <div className="name">{name}</div>
        <div className="title">{title}</div>
        <div className="date">{date}</div>
        <div className="details">
          <div className="link" onClick={() => onDetailsClick()}>
            details/duties
          </div>
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
}
