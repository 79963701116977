import React from "react";

interface ProjectProps {
  className: string;
  title: string;
  image: string;
  children: JSX.Element | string;
}

export default function Project({
  className,
  title,
  image,
  children,
}: ProjectProps) {
  return (
    <div className={className}>
      <div className="project-inner">
        <div className="title">{title}</div>
        <div className="image">
          <img src={image} />
        </div>
        {children}
      </div>
    </div>
  );
}
