import React, { useEffect, useRef, useState } from "react";
import Experience from "../components/experience";
import Header from "../components/header";
import Layout from "../components/layout";
import Project from "../components/project";

import outrunImage from "../images/outrun.png";
import epp2dImage from "../images/epp2d.jpg";
import wallscImage from "../images/wallsc.png";
import dollarJockeyImage from "../images/dollar-jockey.png";
import Resume, { resumeDetails } from "../components/resume";
import resume from "../images/resume.pdf";
import { page } from "../components/sidebar";
import { experiences } from "../library/experience";

export default function Index() {
  const [page, setPage] = useState<page>("none");
  const [navClick, setNavClicked] = useState<page>("none");
  const [selectedResumeDetails, setSelectedResumeDetails] =
    useState<resumeDetails>("none");

  const aboutRef = useRef<HTMLDivElement>(null);
  const experienceRef = useRef<HTMLDivElement>(null);
  const projectsRef = useRef<HTMLDivElement>(null);
  const resumeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    switch (navClick) {
      case "about":
        aboutRef.current?.scrollIntoView();
        break;
      case "experience":
        experienceRef.current?.scrollIntoView();
        break;
      case "projects":
        projectsRef.current?.scrollIntoView();
        break;
      case "resume":
        resumeRef.current?.scrollIntoView();
        break;
    }
  }, [navClick]);

  const listener = (event: Event) => {
    const pages: { ref: React.RefObject<HTMLDivElement>; page: page }[] = [
      { ref: aboutRef, page: "about" },
      { ref: experienceRef, page: "experience" },
      { ref: projectsRef, page: "projects" },
      { ref: resumeRef, page: "resume" },
    ];
    pages.every((item) => {
      var rect = item.ref.current?.getBoundingClientRect();
      if (!rect) {
        return true;
      }

      if (rect.top >= 0) {
        setPage(item.page);
        return false;
      }

      return true;
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", listener);
  }, []);

  return (
    <Layout page={page} onNavClick={(page: page) => setNavClicked(page)}>
      <div className="main">
        <div className="mobile-header">
          <Header />
        </div>
        <div className="about-me section" ref={aboutRef}>
          <h1>About Me</h1>
          <p>
            As a software development manager, I have been responsible for
            leading teams of software engineers that build great products. I
            maintain a track record of building great teams and mentoring them
            to achieve objectives. Passionate about software development, I also
            like improving systems as well as streamlining processes. Over the
            years, this has helped me build the right culture that encourages
            creativity, innovation and continuous improvement in everyone within
            the organization.
          </p>
        </div>
        <div className="experience section" ref={experienceRef}>
          <h1>Experience</h1>
          {experiences.map(
            ({ icon, name, title, date, resumeDetails, children }) => (
              <>
                <Experience
                  icon={icon}
                  name={name}
                  title={title}
                  date={date}
                  onDetailsClick={() => setSelectedResumeDetails(resumeDetails)}
                >
                  {children}
                </Experience>
                <hr />
              </>
            )
          )}
          <div>
            * In addition to this I have another 5 years of experience in
            software development. The majority of this experience is in web
            development and mobile applications.
          </div>
        </div>

        <div className="projects section" ref={projectsRef}>
          <h1>Personal Projects</h1>

          <div>
            <Project
              image={dollarJockeyImage}
              className="unity"
              title="Investment Portfolio"
            >
              <div className="details">
                I created this site to help assess and keep track of all my
                investments. It's become an invaluable tool over the years to
                help me become a better investor. I always enjoy refining and
                adding new features to it.
                <div>
                  <a
                    href="https://github.com/aflesher/investment-portfolio"
                    target="_blank"
                  >
                    Github
                  </a>
                </div>
              </div>
            </Project>
          </div>

          <div>
            <Project
              image={epp2dImage}
              className="unity"
              title="Entity Post-Processor 2D"
            >
              <div className="details">
                One issue I had when developing my game in Unity is that I
                wanted to add effects like outlines and dissolves to our Spine
                2D assets. A solution to doing this was to create a multi-camera
                system that rendered these assets to a render texture. It worked
                great so I built a tool to help automate the setup process.
                <div>
                  <a
                    href="https://github.com/aflesher/EntityPostProcessor2D"
                    target="_blank"
                  >
                    Github
                  </a>{" "}
                  |&nbsp;
                  <a
                    href="https://drive.google.com/open?id=1-eA-qDG59Q6EsTLXh0prcmobsTHtuyp5"
                    target="_blank"
                  >
                    Unity Package
                  </a>
                </div>
              </div>
            </Project>
          </div>
        </div>

        <div className="resume section" ref={resumeRef}>
          <div className="resume-wrapper">
            <Resume selectedResumeDetails={selectedResumeDetails} />
          </div>
        </div>
      </div>
    </Layout>
  );
}
