import React from "react";

interface LinkProps {
  icon: string;
  url: string;
  text: string;
}

export default function Link({ icon, url, text }: LinkProps) {
  return (
    <div className="link row">
      <div className="icon col-2">
        <img src={icon} />
      </div>
      <div className="url col-10">
        <a href={url} target="_blank">
          {text}
        </a>
      </div>
    </div>
  );
}
