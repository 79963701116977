import React, { useState } from "react";
import Sidebar, { SidebarProps } from "./sidebar";

import "../styles/main.scss";

interface LayoutProps extends SidebarProps {
  children: JSX.Element | string;
}

export default function Layout({
  children,
  page,
  onNavClick: onPageChange,
}: LayoutProps) {
  return (
    <div className="page">
      <Sidebar onNavClick={onPageChange} page={page} />
      <>{children}</>
    </div>
  );
}
