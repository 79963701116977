import React from "react";
import { IExperience } from "../types/experience";

import epicLogo from "../images/epic.png";
import semaphoreLogo from "../images/semaphore.jpg";
import darkTerritoryLogo from "../images/logo-dt.png";
import changeLogo from "../images/change.png";
import zyngaLogo from "../images/zynga.png";
import flockImage from "../images/flock-icon.png";
import { resumeDetails } from "../components/resume";

interface IExperienceSection extends IExperience {
  resumeDetails: resumeDetails;
}

const epic: IExperienceSection = {
  icon: epicLogo,
  name: "Epic! Creations Inc",
  title: "Engineering Manager",
  date: "aug 2020 - present",
  resumeDetails: "epic",
  children: (
    <p>
      I filled many different types of roles during my time at Epic. Most
      notably I've been responsible for hiring up an engineer team across
      Canada. I also stepped in to lead the web team at Epic for several months
      while we looked to hire a full time manager into that role. I spend a lot
      of time trying to improve our team's processes and tools while helping my
      reports to do the same.
    </p>
  ),
};

const semaphore: IExperienceSection = {
  icon: semaphoreLogo,
  name: "Semaphore Solutions",
  title: "Contractor/Software Engineer",
  date: "aug 2018 - aug 2020 (2.0 years)",
  resumeDetails: "semaphore",
  children: (
    <p>
      At Semaphore Solutions I work on management software for retirement
      communities for a client of theirs. I balance my full stack development
      duties with managing a small team of engineers. I've helped moved the team
      forward with new features while reducing the enormous amount of tech debt
      in the product.
    </p>
  ),
};

const darkTerritory: IExperienceSection = {
  icon: darkTerritoryLogo,
  name: "Dark Territory",
  title: "Owner/Developer",
  date: "oct 2016 - apr 2018 (1.5 years)",
  resumeDetails: "darkTerritory",
  children: (
    <p>
      In 2016 I left my job to fulfill a lifelong dream of starting an
      independent game studio. I set out to build a procedurally generated 2D
      platformer in Unity. I learned a lot and made some really good progress
      but for various reasons I decided to put the game on hold indefinitely.
      I'm happy for the experience but excited to pursue other interests.
    </p>
  ),
};

const change: IExperienceSection = {
  icon: changeLogo,
  name: "Change.org",
  title: "Principal Software Engineer",
  date: "aug 2014 - may 2016 (1.5 years)",
  resumeDetails: "change",
  children: (
    <p>
      During my time at Change.org I was chosen to lead a small team of
      engineers in building a new web application.{" "}
      <a
        href="http://www.wired.co.uk/article/change-org-change-politics-united-states-presidential-election-2016"
        target="_blank"
      >
        Change Politics
      </a>{" "}
      was built from the ground up using React/Node JS. The engineering side of
      the project was a great success as we were always able to meet all our
      deadlines while maintaining a stable and scalable application. The project
      was ultimately scrapped due to budget constraints but I remain proud of
      the work we did.
    </p>
  ),
};

const zynga: IExperienceSection = {
  icon: zyngaLogo,
  name: "Zynga",
  title: "Principal Software Engineer",
  date: "Jan 2011 - Aug 2014 (3.5 years)",
  resumeDetails: "zynga",
  children: (
    <p>
      I moved to San Francisco to work at Zynga and help build their new web
      platform{" "}
      <a href="http://zynga.com" target="_blank">
        zynga.com
      </a>
      . I led a feature development team that helped to ship the final product.
      Later I joined a team building a native iOS/Android social application as
      a lead engineer.
    </p>
  ),
};

export const experiences: IExperienceSection[] = [
  epic,
  semaphore,
  darkTerritory,
  change,
  zynga,
];
