import React from "react";

const epic: Job = {
  company: "Epic Creations Inc",
  key: "epic",
  position: "Engineering Manager",
  date: "Aug 2020 - Present",
  location: "Victoria, BC",
  children: (
    <ul>
      <li>
        <span>
          Hired as the first Engineer in the capacity of Engineering Manager to
          build and oversee a team of Engineers throughout Canada for various
          technical disciplines
        </span>
      </li>
      <li>
        <span>
          Request directly from the VP of Engineering to run Epic&#39;s Web Team
          in N.A. and India on an interim basis. This was in addition to my
          current responsibilities
        </span>
        <span>&nbsp;of hiring, onboarding, mentoring, forecasting etc.</span>
      </li>
      <li>
        <span>
          Architected an internationalized single codebase solution for
          getepic.com and getepic.com/in which resulted in both teams being able
          to write and deploy code with minimal overhead
        </span>
      </li>
      <li>
        <span>
          Migrated our build and deployment jobs out of Jenkins and into a CD/CD
          GitOps solution which led to more efficient and reliable deployments
        </span>
      </li>
      <li>
        <span>
          Performed an SEO audit of getepic.com and made major improvements that
          led to higher click through rates with resulted in more customer sign
          ups and better experience score from Google
        </span>
      </li>
    </ul>
  ),
};

const semaphore: Job = {
  company: "Semaphore Solutions",
  key: "semaphore",
  position: "Contractor/Software Engineer",
  date: "Aug 2018 - Aug 2020",
  location: "Victoria, BC",
  children: (
    <ul>
      <li>
        <span>
          Lead Engineer for 5 Engineers on different projects in the genomic and
          medical space
        </span>
      </li>
      <li>
        <span>
          Brought in to overhaul existing project. Created and implemented new
          project plan to meet bi-weekly goals that were previously being missed
        </span>
      </li>
      <li>
        <span>
          Hired to build a new app from the ground up that passed a full
          security audit from Casaba
        </span>
      </li>
      <li>
        <span>
          Key owner of meeting all compliance issues related to HIPAA/FDA. All
          releases successfully approved on first submission
        </span>
      </li>
    </ul>
  ),
};

const darkTerritory: Job = {
  company: "Dark Territory",
  key: "darkTerritory",
  position: "Owner/Developer",
  date: "May 2016 - Apr 2018",
  location: "Victoria, BC",
  children: (
    <ul>
      <li>
        <span>Started a self-funded independent game company</span>
      </li>
      <li>
        <span>
          Designed and built tools for building and testing a procedurally
          generated 2D game in Unity
        </span>
      </li>
      <li>
        <span>
          Optimized game to run at a stutter free +60fps and to generate levels
          in less than 100 milliseconds
        </span>
      </li>
      <li>
        <span>
          Created and open sourced custom multi-camera system for applying
          post-processing effects to Spine assets
        </span>
      </li>
      <li>
        <span>Recruited and hired a Designer to create in game assets</span>
      </li>
    </ul>
  ),
};

const change: Job = {
  company: "Change.org",
  key: "change",
  position: "Principal Software Engineer",
  date: "Aug 2014 - May 2016",
  location: "Victoria, BC",
  children: (
    <ul>
      <li>
        <span>
          Led a team of 6 Engineers to build a new web application for voters in
          the United States
        </span>
      </li>
      <li>
        <span>
          Met every aggressive hard deadline for the project which centered
          around election cycles
        </span>
      </li>
      <li>
        <span>
          As a solo developer built a proof of concept app which allowed
          significant and important changes to be made on the final app without
          incurring any tech debt
        </span>
      </li>
      <li>
        <span>
          Successfully architected a new web application from the ground up
          using a newer technology in addition to training the team on that
          technology
        </span>
      </li>
      <li>
        <span>
          Took responsibility for meeting project deadlines while partnering
          with project management
        </span>
      </li>
    </ul>
  ),
};

const zynga: Job = {
  company: "Zynga",
  key: "zynga",
  position: "Principal Software Engineer",
  date: "Jan 2011 - Aug 2014",
  location: "San Francisco, CA",
  children: (
    <ul>
      <li>
        <span>L</span>
        <span>
          ead engineer of 6 person team working on Zynga.com and then a native
          iOS application
        </span>
      </li>
      <li>
        <span>
          Architected a solution for the iOS app that replaced the existing
          problematic HTML approach with a native one
        </span>
      </li>
      <li>
        <span>
          Completed an audit of iOS app to significantly reduce CPU and battery
          consumption
        </span>
      </li>
      <li>
        <span>
          Brought in to work on stalled/challenging projects to quickly identify
          problems and create solutions for a timely release
        </span>
      </li>
      <li>
        <span>
          Completely rewrote our deployment scripts to massively reduce
          deployment times and failed deployments
        </span>
      </li>
    </ul>
  ),
};

export const jobs = [epic, semaphore, darkTerritory, change, zynga];
