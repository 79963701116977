import React from "react";
import Header from "./header";

export type page = "none" | "about" | "experience" | "projects" | "resume";

const pages: { title: string; page: page }[] = [
  { title: "About Me", page: "about" },
  { title: "Experience", page: "experience" },
  { title: "Projects", page: "projects" },
  { title: "Resume", page: "resume" },
];

export interface SidebarProps {
  page: page;
  onNavClick: (page: page) => void;
}

export default function Sidebar({
  page: activePage,
  onNavClick,
}: SidebarProps) {
  return (
    <div className="sidebar-wrapper">
      <div className="sidebar">
        <Header />
        <div className="nav-items">
          {pages.map(({ title, page }) => (
            <div
              className={`${page === activePage ? "active" : ""} nav-item`}
              onClick={() => onNavClick(page)}
              key={page}
            >
              {title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
